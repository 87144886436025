<template>
    <section class="personnel">
        <div class="top-bar bg-white" style="justify-content: left">
            <el-input v-model="formSearch.keyword" placeholder="请输入关键字搜索" style="margin: 0 5px; width: 250px" size="small"/>
            <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button size="small" @click="handleReset">重置</el-button>
            <el-button size="small" type="primary" icon="el-icon-plus" @click="openAdd">添加</el-button>
        </div>
        <r-e-table class="bg-white" ref="tableRef" :dataRequest="getLeasorListApi" :columns="tableColumn"
                   :query="formSearch" :height="810">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="150">
                <template slot-scope="{ row }">
                    <div class="table-tools" style="font-size: 16px">
                        <span class="table-btn" @click="openAdd(row)"><i class="el-icon-edit-outline"></i></span>
                        <span class="table-btn" @click="viewPhotos(row)"><i class="el-icon-full-screen"></i></span>
                        <span class="table-btn" @click="details(row)"><i class="el-icon-delete"></i></span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>
        <dialog-save-personnel ref="dialogSavePersonnel"/>
        <r-e-dialog title="图片详情" class="layer-contract-detail" :visible.sync="dialogVisible" top="10vh">
            <div class="flex" style="height: 500px;overflow:scroll;">
                <div v-for="(item,index) in imgList" :key="index">
                    <el-image :src="createFullImageUrl(item)" class="flex align-center"
                              :preview-src-list="[createFullImageUrl(item)]">
                        <div slot="error" style="font-size: 30px;"><i class="el-icon-picture-outline"></i></div>
                        <div slot="placeholder">加载中...</div>
                    </el-image>
                </div>
            </div>
        </r-e-dialog>
    </section>
</template>

<script>
    import {personnelTableColumn} from "@/views/property-management/entrance-guard/data";
    import {createFullImageUrl} from "@/components/Upload/index";
    import {getLeasorListApi} from "@/api/entrance-guard";
    export default {
        name: "personnel",
        data() {
            return {
                formSearch: {
                    keyword: null
                },
                tableColumn: personnelTableColumn,
                imgList: [],
                dialogVisible: false,
            };
        },
        components: {
            dialogSavePersonnel:()=>import("./components/dialog-save-personnel")
        },
        methods: {
            getLeasorListApi(params){
                return getLeasorListApi(params);
            },
            openAdd() {
                this.$refs["dialogSavePersonnel"].openDialog();
            },
            handleSearch() {
                this.$refs["tableRef"].pNumber = 1;
                this.$refs["tableRef"].getTableData();
            },
            handleReset(){
                this.formSearch.keyword = null;
                this.handleSearch();
            },
            details(data) {
                let {uuid} = data;
            },
            createFullImageUrl(uuid) {
                return createFullImageUrl(uuid)
            },
            viewPhotos(data){
                let {photo} = data;
                this.imgList = photo ? photo.split(";").filter(item => item) : [];
                this.dialogVisible = true;
            },
        },
        async mounted() {
        },
        watch: {}
    }
</script>

<style lang="scss" scoped>
    .personnel {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(10px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }

        .title {
            height: VH(50px);
            line-height: VH(50px);
            color: #666;
            padding-left: VW(10px);
            position: relative;

            &::before {
                width: 5px;
                height: 40%;
                background-color: #5C84FB;
                content: '';
                position: absolute;
                left: 0;
                top: 30%;
            }
        }
    }
</style>